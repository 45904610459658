import * as React from "react"
import PropTypes from "prop-types"
import Button from '@material-ui/core/Button'
import Scrollspy from 'react-scrollspy'
import { Link } from "gatsby"

import pagelogo from '../../images/mainpage_logo.png'

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
      padding: `20px 20px`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `center`,
        maxWidth: 1160,
        
      }}
    >
    
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
            textTransform: `uppercase`,
            fontSize: `16px`,
            display: `block`,
            width: `146px`
          }}
        >
          <img src={pagelogo} alt="logo"/>
        </Link>
      {/* <nav className="site_nav">
        <ul style={{display: `flex`, justifyContent: `space-around`, alignItems: `center`}}>
          <li>
            <Link to="/about" style={{fontWeight: `400`}}>
            Про чемпіонат
            </Link>
          </li>
        </ul>
      </nav> */}
      <Scrollspy items={ ['section-1'] } currentClassName="is-current">
          <a href="#section-1" style={{textDecoration: `none`, display: `block`}}><Button variant="outlined">  РЕЄСТРАЦІЯ</Button></a>
      </Scrollspy>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
