import React from 'react'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';

import vizamaster from "../../images/visamaster.png"

const Footer = ({title}) => {
    return (
        <footer
        style={{
          marginTop: `2rem`,
          padding: `20px`,
          background: `#EEF8FA`
        }}

      >
        <div className="container">
          <p style={{textAlign: `center`, marginBottom: `8px`}}>
          ТОВ «Смарт Простір Бояр»
Львів, вул. Виговського, 5б

          </p>
          <p style={{textAlign: `center`, display: `flex`, justifyContent: `center`, alignItems: `center`, marginBottom: `8px`}}>
            <PhoneIcon  />
            <a href="tel:+380976293601" style={{textDecoration: `none`, color: `black`, marginLeft: `6px`, marginBottom: `8px`}}>+38(097)629-36-01</a>
          </p>
          <p style={{textAlign: `center`, display: `flex`, justifyContent: `center`, alignItems: `center`, marginBottom: `8px`}}>
            <MailOutlineIcon />
          <a href="mailto:camp@boyar.info" style={{textDecoration: `none`, color: `black`, marginLeft: `6px`}}>camp@boyar.info</a>
          </p>
            <a
                href="http://uapuzzle.com/docs/"
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  padding: `10px`,marginBottom: `10px`,
                  textDecoration: `none`,
                  margim: `0 auto`,
                  display: `block`,
                  fontWeight: `bold`,
                  textAlign: `center`, color: `black`
                }}
              >
                Договір оферти
              </a>

              <a
                href="https://uapuzzle.com/docs/confident"
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  padding: `10px`,marginBottom: `10px`,
                  textDecoration: `none`,
                  margim: `0 auto`,
                  display: `block`,
                  fontWeight: `bold`,
                  textAlign: `center`,
                  color: `black`
                }}
              >
                Політика конфіденційності
              </a>

              

              <div style={{ margin: `0 auto`, width: `88px`}}>
                <img src={vizamaster} alt="payment" />
              </div>
        <p style={{textAlign: `center`}}>
        © {new Date().getFullYear()}, {title}

        </p>
        </div>
     
        </footer>
    )
}

export default Footer
